import React, { useState } from "react";
import Navigation from "../../../components/Layout/Navigation/Navigation.js";
import Styles from "./ContactUs.module.css";
import { Input } from "../../../components/Common/index.js";
import Button from "../../../components/Common/Button/Button.js";
import Seo from "../../../utils/SeoHelmet/Seo.js";
import axios from "axios";
import { CircularProgress } from "@mui/joy";

const ContacUs = () => {
  const [formValues, setFormValues] = useState({
    name: null,
    email: null,
    message: null,
  });
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(false);
  const checkValues = (values) => {
    const errors = {};

    const isValid = Object.keys(values).every((key) => {
      if (values[key] === null || values[key] === "") {
        errors[key] = "Can't be empty";
        return false;
      } else {
        errors[key] = null;
        return true;
      }
    });

    setError(errors);
    return isValid;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const check = checkValues(formValues);
      if (check) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formValues.email)) {
          setError("Email not valid.");
          return;
        }
        setLoading(true);
        const delay = new Promise((resolve) => setTimeout(resolve, 3000));
        console.log(formValues);
        const ip = await axios.get("https://api.ipify.org/?format=json");
        const [res] = await Promise.all([
          axios.post("/api/support/email", { ...formValues, ...ip.data }),
          delay,
        ]);

        if (res.status === 200) {
          setSuccess(res.data);
          setError();
          setLoading(false);
        }
        setLoading(false);
      } else {
        console.log("Solve errors");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={Styles.Page}>
      <Seo description={"Contact us for more informations about rule of 40."} />
      <Navigation />
      <div className={Styles.PageContent}>
        <div className={Styles.PageTitle}>
          <h3>Contact Us</h3>
          <p>
            Need more information? Feel free to contact us with the form below.
          </p>
        </div>
        <form className={Styles.Form}>
          <Input
            label
            textLabel={"Name"}
            required
            error={error ? error.name : null}
            placeholder={"Your name"}
            onChange={(e) =>
              setFormValues((prev) => {
                return { ...prev, name: e.target.value };
              })
            }
          />
          <Input
            label
            textLabel={"Email"}
            required
            error={error ? error.email : null}
            placeholder={"example@email.com"}
            onChange={(e) =>
              setFormValues((prev) => {
                return { ...prev, email: e.target.value };
              })
            }
          />
          <Input
            label
            textLabel={"Message"}
            required
            error={error ? error.message : null}
            type={"textarea"}
            placeholder={"Leave us a message..."}
            onChange={(e) =>
              setFormValues((prev) => {
                return { ...prev, message: e.target.value };
              })
            }
          />
          {loading ? (
            <CircularProgress />
          ) : !loading && success && success.message ? (
            <p className={Styles.success}>{success.message}</p>
          ) : (
            <Button
              style={{ width: "100%", borderRadius: "8px" }}
              onClick={onSubmit}
            >
              Submit
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContacUs;
