import React, { useEffect, useState } from "react";
import Navigation from "../../../components/Layout/Navigation/Navigation.js";
import Styles from "./BlogPage.module.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw } from "draft-js";
import { CircularProgress } from "@mui/material";

const BlogPage = () => {
  const [blogPost, setBlogPost] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const blogPostResponse = await axios.get(`/api/blog/${id}`);
        setBlogPost(blogPostResponse?.data[0]);
        if (blogPostResponse?.data[0]?.blog_content) {
          const contentState = convertFromRaw(
            JSON.parse(blogPostResponse?.data[0].blog_content)
          );
          setEditorState(EditorState.createWithContent(contentState));
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [id]);
  const date = blogPost && new Date(blogPost?.created_at);
  return (
    <div className={Styles.App}>
      <Navigation />
      {blogPost && !loading ? (
        <div className={Styles.ContentWrapper}>
          <div className={Styles.CategoryDateWrapper}>
            <span className={Styles.CategoryWrapper}>
              {blogPost.blog_category}
            </span>
            <p> {new Intl.DateTimeFormat("en-US").format(date)}</p>
          </div>
          <div className={Styles.ContentTitleWrapper}>
            <h2>{blogPost.blog_title}</h2>
            <p>{blogPost.blog_subtitle}</p>
          </div>
          <div className={Styles.Line}></div>
          <div className={Styles.BlogContent}>
            <Editor
              editorState={editorState}
              readOnly={true}
              toolbarHidden={true}
              editorClassName={Styles.rdwEditorMain}
            />
          </div>
        </div>
      ) : (
        <div>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default BlogPage;
