import React from "react";
import Styles from "./Pagination.module.css";
import usePaginationLogic from "../Logic/PaginationLogic.js";
import useWindowDimensions from "../../../../hooks/useWindowDimensions.js";

const CustomPagination = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const { renderPageNumbers } = usePaginationLogic({
    count,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
  });
  const { width } = useWindowDimensions();
  return (
    <div className={Styles.paginationWrapper}>
      <span
        style={
          width < 900
            ? { display: "none" }
            : {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                width: "100px",
              }
        }
      >{`${page * rowsPerPage + 1}-${Math.min(
        (page + 1) * rowsPerPage,
        count
      )} of ${count}`}</span>
      <div className={Styles.paginationMiddleContent}>
        {/* <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 0}
          style={width < 900 ? { display: "none" } : null}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.2325 4.18414C10.4622 4.423 10.4548 4.80282 10.2159 5.0325L7.06569 8L10.2159 10.9675C10.4548 11.1972 10.4622 11.577 10.2325 11.8159C10.0028 12.0547 9.62302 12.0622 9.38416 11.8325L5.78416 8.4325C5.66651 8.31938 5.60002 8.16321 5.60002 8C5.60002 7.83679 5.66651 7.68062 5.78416 7.5675L9.38416 4.1675C9.62302 3.93782 10.0028 3.94527 10.2325 4.18414Z"
              fill="#495867"
            />
          </svg>
          Back
        </button> */}
        {renderPageNumbers()}
        {/* <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages - 1}
          style={width < 900 ? { display: "none" } : null}
        >
          Next{" "}
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.76748 11.8159C5.5378 11.577 5.54525 11.1972 5.78411 10.9675L8.93431 8L5.78411 5.0325C5.54525 4.80282 5.5378 4.423 5.76748 4.18413C5.99715 3.94527 6.37698 3.93782 6.61584 4.1675L10.2158 7.5675C10.3335 7.68062 10.4 7.83679 10.4 8C10.4 8.16321 10.3335 8.31938 10.2158 8.4325L6.61584 11.8325C6.37698 12.0622 5.99715 12.0547 5.76748 11.8159Z"
              fill="#495867"
            />
          </svg>
        </button> */}
      </div>
      <div
        className={Styles.PageSelection}
        style={width < 900 ? { display: "none" } : null}
      >
        <select
          value={rowsPerPage}
          onChange={(e) => onRowsPerPageChange(parseInt(e.target.value, 10))}
        >
          {[10, 25, 50].map((size) => (
            <option key={size} value={size}>
              {` ${size}`}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CustomPagination;
