import Styles from "./Dashboard.module.css";
import React, { useCallback, useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
// import { zodResolver } from "@hookform/resolvers/zod";
// import * as z from "zod";
import CustomPagination from "../../../components/Common/Pagination/ui/Pagination.js";
import { Input } from "../../../components/Common/index.js";
import { CircularProgress } from "@mui/joy";
import {
  ChartArc,
  Navigation,
  Table,
} from "../../../components/Layout/index.js";

import {
  useFiltersData,
  useChartData,
  useDashboardColumns,
  useDashboardLogic,
} from "../Logic/index.js";
import Modal from "../../../components/Common/Modal/Modal.js";
import Button from "../../../components/Common/Button/Button.js";
import useCredentialLogic from "../Logic/useCredentialLogic.js";
import CustomSlider from "../../../components/Common/CustomSlider/CustomSlider.js";
import FiltersIsland from "../../../components/Layout/FiltersIsland/FiltersIsland.js";
import Seo from "../../../utils/SeoHelmet/Seo.js";
import { publicIpv4 } from "public-ip";
import axios from "axios";
import companyImg from "../../../images/companyPage.png";

const Dashboard = () => {
  const [conditions, setConditions] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState([-100, 100]);
  const [filters, setFilters] = useState({
    exchange_short_name: null,
    rule_of_forty: null,
    industry: null,
    sector: null,
  });
  const [stockSymbol, setStockSymbol] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [modalCheckboxValue, setModalCheckboxValue] = useState([]);
  const [nameEmail, setNameEmail] = useState({
    name: null,
    email: null,
    emailBody: null,
    columns: [],
  });
  const [symbolModal, setSymbolModal] = useState(false);
  const [symbol, setSymbol] = useState();
  const [limitModal, setLimitModal] = useState(false);
  const [limit, setLimit] = useState(false);
  const [popUpInitial, setPopUpInitial] = useState(false);

  //Hooks
  const {
    industries,
    sectors,
    exchange,
    filterIndustry,
    filterSector,
    filterExchange,
  } = useFiltersData(filters, setConditions, value);
  const {
    columns,
    displayColumns,
    sortField,
    sortType,
    handleCheckboxClick,
    handleResetColumn,
  } = useDashboardColumns(setSymbolModal, setSymbol);
  const { data, csvDataFiltered, clearFilters } = useDashboardLogic(
    conditions,
    page,
    rowsPerPage,
    stockSymbol,
    sortField,
    sortType,
    displayColumns,
    setFilters,
    handleResetColumn
  );

  const chartData = useChartData(conditions, stockSymbol);

  const setStockSymbolCallback = useCallback((symbol) => {
    setStockSymbol(symbol);
  }, []);

  const { updateCredentials, onSubmit, error, loading, success } =
    useCredentialLogic({
      setNameEmail,
      nameEmail,
      limit,
      setOpenModal,
      setLimitModal,
    });

  const handleMouseUp = () => {
    if (
      value[0] !== -100 &&
      value[0] !== 100 &&
      value[1] !== -100 &&
      value[1] !== 100
    ) {
      setFilters((prev) => {
        return {
          ...prev,
          rule_of_forty: ["btwn"],
        };
      });
    }

    if (value[0] === -100 && value[1] !== 100) {
      setFilters((prev) => {
        return {
          ...prev,
          rule_of_forty: ["<"],
        };
      });
    }
    if (value[0] !== -100 && value[1] === 100) {
      setFilters((prev) => {
        return {
          ...prev,
          rule_of_forty: [">"],
        };
      });
    }
    if (value[0] === -100 && value[1] === 100) {
      setFilters((prev) => {
        return {
          ...prev,
          rule_of_forty: null,
        };
      });
    }
  };

  const formatString = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    const formatCheckboxValue = () => {
      const formattedMap = Object.keys(displayColumns).reduce((acc, item) => {
        acc[item] = {
          key: item,
          text: formatString(item),
        };
        return acc;
      }, {});
      setModalCheckboxValue(formattedMap);
    };
    formatCheckboxValue();
  }, [displayColumns]);

  useEffect(() => {
    const isIpChecked = sessionStorage.getItem("ipcheck");
    const isPopUpInitial = sessionStorage.getItem("popup-modal");
    if (!isIpChecked) {
      const checkIp = async () => {
        try {
          const body = {
            ip: await publicIpv4(),
          };

          const check = await axios.post("/api/auth/ipchecker", body);
          if (check.status === 200) {
            sessionStorage.setItem("ipcheck", "true");
            if (!isPopUpInitial) {
              sessionStorage.setItem("popup-modal", "true");
              setTimeout(() => setPopUpInitial(true), 5000);
            }
          }
        } catch (error) {
          console.log(error?.response?.data);
          if (
            error?.response?.data?.message === "Limit reached" &&
            error?.response?.data?.count === 3
          ) {
            setLimitModal(true);
            setLimit(true);
            setOpenModal(false);
            setPopUpInitial(false);
          }
        }
      };
      checkIp();
    } else {
      console.log("ip already checked");
      if (!isPopUpInitial) {
        sessionStorage.setItem("popup-modal", "true");
        setTimeout(() => setPopUpInitial(true), 5000);
      }
    }
  }, []);

  const handleOpenPage = () => {
    if (symbol) {
      const url = `https://app.levelfields.ai/home/?symbol=${symbol}`;
      window.open(url, "_blank");
    }
  };

  return (
    <div className={Styles.App}>
      <Seo
        description={
          "Rule of 40 tool. Check the companies that respect this rule."
        }
      />
      <>
        {data.result && industries && chartData && sectors && exchange ? (
          <div className={Styles.AppContent}>
            <Navigation
              setStockSymbol={setStockSymbolCallback}
              data={csvDataFiltered}
              setOpenModal={setOpenModal}
            />
            <div className={Styles.MainContent}>
              <div className={Styles.ContentWrapper}>
                <ChartArc chartData={chartData} data={data} />
                <div>
                  <p
                    style={{
                      margin: "15px 0px",
                      textAlign: "center",
                    }}
                  >
                    Range your rule
                  </p>
                  <CustomSlider
                    value={value}
                    setValue={setValue}
                    handleMouseUp={handleMouseUp}
                  />
                </div>

                <div className={Styles.TableWrapper}>
                  <div className={Styles.TableContent}>
                    <div className={Styles.TableBorder}>
                      <Table data={data.result} columns={columns} />

                      <div className={Styles.PaginationWrapper}>
                        <CustomPagination
                          count={data?.count}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={(newPage) => setPage(newPage)}
                          onRowsPerPageChange={(newRowsPerPage) => {
                            setRowsPerPage(newRowsPerPage);
                            setPage(0);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <FiltersIsland
                  displayColumns={displayColumns}
                  handleCheckboxClick={handleCheckboxClick}
                  clearFilters={clearFilters}
                  filters={filters}
                  setFilters={setFilters}
                  filterIndustry={filterIndustry}
                  filterSector={filterSector}
                  filterExchange={filterExchange}
                />
              </div>
            </div>
            <Modal
              open={openModal}
              setOpen={() => {
                setOpenModal(false);
                if (limit) {
                  setLimitModal(true);
                }
              }}
              title={"Request the data with this form."}
              closeBtn={true}
              mobile={true}
            >
              <Input
                label
                textLabel={"Full Name"}
                required
                placeholder={"Name"}
                name="name"
                error={error === "Name is missing." ? true : false}
                onChange={(e) => updateCredentials(e)}
              />
              <Input
                label
                type={"email"}
                textLabel={"Email"}
                required
                placeholder={"example@email.com"}
                name="email"
                error={
                  error === "Email is missing." || error === "Email not valid."
                    ? true
                    : false
                }
                onChange={(e) => updateCredentials(e)}
              />
              <Input
                label
                type={"textarea"}
                textLabel={"Message"}
                required
                placeholder={"Send us a message..."}
                name="emailBody"
                onChange={(e) => updateCredentials(e)}
              />
              <div className={Styles.ColumnsSelectionOutsideWrapper}>
                <h5>
                  Request columns <span className={Styles.Required}>*</span>
                </h5>
                {/* <div className={Styles.ColumnsSelectionWrapper}>
                  <div className={Styles.ColumnsSelection}>
                    {Object.values(modalCheckboxValue)
                      .map((item, index) => {
                        return (
                          <Input
                            id={`checkbox-value-${item.key}`}
                            type="checkbox"
                            name="columns"
                            value={item.key}
                            textLabel={item.text}
                            className={Styles.Checkbox}
                            onChange={(e) => updateCredentials(e)}
                            index={index}
                          />
                        );
                      })
                      .slice(
                        0,
                        Math.round(Object.keys(displayColumns).length / 2)
                      )}
                  </div>
                  <div className={Styles.ColumnsSelection}>
                    {Object.values(modalCheckboxValue)
                      .map((item, index) => {
                        return (
                          <Input
                            id={`checkbox-value-${item.key}`}
                            type="checkbox"
                            name="columns"
                            value={item.key}
                            textLabel={item.text}
                            className={Styles.Checkbox}
                            onChange={(e) => updateCredentials(e)}
                            index={index}
                          />
                        );
                      })
                      .slice(
                        Math.round(Object.keys(modalCheckboxValue).length / 2),
                        Object.keys(modalCheckboxValue).length
                      )}
                  </div>
                </div> */}
                <div className={Styles.ColumnsSelectionWrapper}>
                  <div className={Styles.ColumnsSelection}>
                    {Object.values(modalCheckboxValue)
                      .slice(
                        0,
                        Math.ceil(Object.keys(modalCheckboxValue).length / 3)
                      )
                      .map((item, index) => (
                        <Input
                          key={item.key}
                          id={`checkbox-value-${item.key}`}
                          type="checkbox"
                          name="columns"
                          value={item.key}
                          textLabel={item.text}
                          className={Styles.Checkbox}
                          onChange={(e) => updateCredentials(e)}
                          index={index}
                        />
                      ))}
                  </div>
                  <div className={Styles.ColumnsSelection}>
                    {Object.values(modalCheckboxValue)
                      .slice(
                        Math.ceil(Object.keys(modalCheckboxValue).length / 3),
                        Math.ceil(Object.keys(modalCheckboxValue).length / 3) *
                          2
                      )
                      .map((item, index) => (
                        <Input
                          key={item.key}
                          id={`checkbox-value-${item.key}`}
                          type="checkbox"
                          name="columns"
                          value={item.key}
                          textLabel={item.text}
                          className={Styles.Checkbox}
                          onChange={(e) => updateCredentials(e)}
                          index={index}
                        />
                      ))}
                  </div>
                  <div className={Styles.ColumnsSelection}>
                    {Object.values(modalCheckboxValue)
                      .slice(
                        Math.ceil(Object.keys(modalCheckboxValue).length / 3) *
                          2,
                        Object.keys(modalCheckboxValue).length
                      )
                      .map((item, index) => (
                        <Input
                          key={item.key}
                          id={`checkbox-value-${item.key}`}
                          type="checkbox"
                          name="columns"
                          value={item.key}
                          textLabel={item.text}
                          className={Styles.Checkbox}
                          onChange={(e) => updateCredentials(e)}
                          index={index}
                        />
                      ))}
                  </div>
                </div>
              </div>
              {loading ? (
                <CircularProgress />
              ) : success && openModal ? (
                <div className={Styles.SuccessMessage}>
                  <p>{success.message}</p>
                </div>
              ) : (
                <Button style={{ width: "100%" }} onClick={onSubmit}>
                  Submit
                </Button>
              )}
            </Modal>
            <Modal open={limitModal} title={"Ops..."}>
              <div className={Styles.ModalBody}>
                <p>
                  It looks like you really like our website, you hit the daily
                  limit. But if you want to continue please let us know which
                  data you need.
                </p>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => {
                    setLimitModal(false);
                    setOpenModal(true);
                  }}
                >
                  Request Data
                </Button>
              </div>
            </Modal>
            <Modal
              open={popUpInitial}
              title={"Welcome on Rule of 40"}
              closeBtn={true}
              setOpen={() => setPopUpInitial(false)}
            >
              <div className={Styles.ModalBody}>
                <p>
                  We're happy to connect with you. If you want to request more
                  specific data please click below.
                </p>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => {
                    setPopUpInitial(false);
                    setOpenModal(true);
                  }}
                >
                  Request Data
                </Button>
              </div>
            </Modal>
            <Modal
              open={symbolModal}
              closeBtn={true}
              setOpen={() => setSymbolModal(false)}
              title={"Access company details"}
            >
              <img
                src={companyImg}
                alt="company"
                className={Styles.CompanyImg}
              />
              <p
                style={{
                  margin: "0px",
                }}
              >
                Hi get more details about the stocks you care. Click the button
                below to access Levelfields and get more data.
              </p>
              <Button
                onClick={handleOpenPage}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                }}
              >
                Go to Levelfields
              </Button>
            </Modal>
          </div>
        ) : (
          <div className={Styles.Loader}>
            <CircularProgress />
          </div>
        )}
      </>
    </div>
  );
};

export default Dashboard;
