import React, { useEffect, useState } from "react";
import { Input } from "../../Common/index.js";
import Styles from "./Navigation.module.css";
import Button from "../../Common/Button/Button.js";
import { useLocation, useNavigate } from "react-router-dom";
import hamburger from "../../../images/hamburger.svg";
import RightSideModal from "../../Common/RightSideModal/RightSideModal.js";
import useWindowDimensions from "../../../hooks/useWindowDimensions.js";
const Navigation = ({ setStockSymbol, setOpenModal }) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [rightSideModal, setRightSideModal] = useState(false);
  const { width } = useWindowDimensions();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/") {
      const timeOut = setTimeout(() => setStockSymbol(search), 500);
      return () => clearTimeout(timeOut);
    }
  }, [search, setStockSymbol, pathname]);
  const handleNavigateAbout = () => {
    window.open("https://www.levelfields.ai/about", "_blank");
  };
  const handleNavigateNews = () => {
    window.open("https://fintechnews.levelfields.ai/");
  };

  const navOptions = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "FAQ",
      path: "/faq",
    },
    {
      name: "Contact us",
      path: "/contact-us",
    },
    {
      name: "About Us",
      func: handleNavigateAbout,
    },
    {
      name: "Blog",
      path: "/blog",
    },
    {
      name: "News",
      func: handleNavigateNews,
    },
    {
      name: "Login",
      path: "/login",
    },
  ];

  return (
    <div className={Styles.Navigation}>
      <div className={Styles.NavigationContent}>
        <div className={Styles.LeftSideNav}>
          <div
            className={Styles.Hamburger}
            onClick={() => setRightSideModal(true)}
          >
            <img src={hamburger} alt="hamburger" width={20} />
          </div>
          {width < 700 ? (
            <h2 onClick={() => navigate("/")}>Rule of 40</h2>
          ) : null}
        </div>
        {width > 700 ? <h2 onClick={() => navigate("/")}>Rule of 40</h2> : null}
        <div className={Styles.RightSideNav}>
          {pathname === "/" ? (
            <>
              <div className={Styles.SearchBar}>
                <Input
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={"Search Symbol..."}
                />
              </div>
              <div className={Styles.Buttons}>
                <Button
                  style={{
                    height: "40px",
                  }}
                  variant={"black"}
                  onClick={() => setOpenModal((prev) => !prev)}
                >
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                    />
                  </svg> */}
                  Download
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <RightSideModal
        open={rightSideModal}
        setOpen={setRightSideModal}
        options={navOptions}
      />
    </div>
  );
};

export default Navigation;
