import React, { useEffect, useState } from "react";
import Navigation from "../../../components/Layout/Navigation/Navigation.js";
import Input from "../../../components/Common/Input/Input.js";
import Button from "../../../components/Common/Button/Button.js";
import Styles from "./BlogsDashboard.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CustomPagination from "../../../components/Common/Pagination/ui/Pagination.js";
import addSvg from "../../../images/add.svg";
import editSvg from "../../../images/edit.svg";
import { CircularProgress } from "@mui/material";

const BlogsDashboard = ({ isAuthenticated }) => {
  const [blogList, setBlogList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [blogsPerPage] = useState(10);

  useEffect(() => {
    const fetchBlogList = async () => {
      try {
        const body = {
          size: blogsPerPage,
          offset: page * blogsPerPage,
          query: query,
        };
        const fetchList = await axios.post("/api/blog", body);
        setBlogList(fetchList.data.result);
        setTotal(+fetchList.data.total);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchBlogList();
  }, [blogsPerPage, page, query]);
  return (
    <div className={Styles.App}>
      <Navigation />
      {loading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={Styles.Hero}>
            <h1>Stock News and Analysis </h1>
            <div
              style={{
                width: "100%",
                maxWidth: "400px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Input
                placeholder={"Search name"}
                onChange={(e) =>
                  setTimeout(() => {
                    setQuery(e.target.value);
                  }, 300)
                }
              />
              {isAuthenticated ? (
                <Button
                  onClick={() => navigate("/blog/add")}
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                >
                  <img src={addSvg} alt="add" width={15} height={15} />
                </Button>
              ) : null}
            </div>
          </div>
          <div className={Styles.BlogGrid}>
            {blogList.length > 0
              ? blogList.map((blog) => {
                  const date = new Date(blog.created_at);
                  return (
                    <div key={blog.blog_id}>
                      <div className={Styles.RelativeBlogImage}>
                        <img
                          src={require(`../../../images/category/${blog.blog_category.replace(
                            / /g,
                            "_"
                          )}.jpg`)}
                          alt="img-blog"
                          className={Styles.BlogImage}
                        />
                        <div className={Styles.ImageBottomGlass}>
                          <div className={Styles.ImageBottomGlassLeft}>
                            <p className={Styles.TitleImageBottomGlass}>
                              Levelfields
                            </p>
                            <p>
                              {new Intl.DateTimeFormat("en-US").format(date)}
                            </p>
                          </div>
                          <div className={Styles.ImageBottomGlassRight}>
                            <p>{blog.blog_category}</p>
                          </div>
                        </div>
                      </div>
                      <div className={Styles.BlogText}>
                        <div>
                          <h3 onClick={() => navigate(`/blog/${blog.blog_id}`)}>
                            {blog.blog_title}
                          </h3>
                          {isAuthenticated ? (
                            <div
                              onClick={() =>
                                navigate(`/blog/edit/${blog.blog_id}`)
                              }
                              className={Styles.IconWrapper}
                            >
                              <img
                                alt="edit"
                                src={editSvg}
                                height={20}
                                width={20}
                              />
                            </div>
                          ) : null}
                        </div>
                        <p>{blog.blog_subtitle}</p>
                        <button
                          onClick={() => navigate(`/blog/${blog.blog_id}`)}
                        >
                          Read
                        </button>
                      </div>
                    </div>
                  );
                })
              : "No Results"}
          </div>
          <div className={Styles.BlogPagination}>
            <CustomPagination
              count={total}
              rowsPerPage={blogsPerPage}
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BlogsDashboard;
