import React, { useEffect } from "react";
import Styles from "./Modal.module.css";
import closeSvg from "../../../images/close.svg";
import Button from "../Button/Button.js";

const Modal = ({ open, setOpen, children, title, closeBtn, mobile }) => {
  useEffect(() => {
    // Disabilita lo scroll quando la modale è aperta
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup: Ripristina lo scroll al termine del componente
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);
  return open ? (
    <div className={Styles.Backdrop}>
      <div className={mobile ? Styles.ModalWrapper : Styles.ModalWrapperNormal}>
        <div className={Styles.ModalHeader}>
          <h5 className={Styles.Title}>{title}</h5>
          {closeBtn ? (
            <Button
              style={{
                background: "none",
              }}
              onClick={() => setOpen(false)}
            >
              <img src={closeSvg} alt="close" width={20} height={20} />
            </Button>
          ) : null}
        </div>
        <div className={Styles.Body}>{children}</div>
      </div>
    </div>
  ) : null;
};

export default Modal;
