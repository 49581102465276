import React, { useEffect, useRef, useState } from "react";
import Styles from "./AddBlogPost.module.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import setAuthToken from "../../../utils/setAuthToken.js";
import Navigation from "../../../components/Layout/Navigation/Navigation.js";
import Input from "../../../components/Common/Input/Input.js";
import Button from "../../../components/Common/Button/Button.js";

const AddBlogPost = () => {
  const [formData, setFormData] = useState({
    blog_title: "",
    blog_subtitle: "",
    blog_content: "",
    blog_category: "",
    blog_microsite: [],
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [multiSelectionModal, setMultiSelectionModal] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const options = [
    { name: "" },
    { name: "Stock Earnings Results" },
    { name: "AI Investing" },
    { name: "Trading Options with AI" },
    { name: "Sectors & Industries" },
    { name: "Leadership Changes" },
    { name: "Healthcare Stocks" },
    { name: "Deals" },
    { name: "Dividends" },
    { name: "Layoffs" },
    { name: "Trading Strategies" },
    { name: "AI" },
    { name: "Buybacks" },
  ];
  const multiOptions = [
    {
      name: "Rule Of 40",
      value: "Rule_Of_40",
      checked: formData.blog_microsite.includes("Rule_Of_40"),
    },
    {
      name: "13F Reports",
      value: "13F_Reports",
      checked: formData.blog_microsite.includes("13F_Reports"),
    },
    {
      name: "Fintech News",
      value: "Fintech_News",
      checked: formData.blog_microsite.includes("Fintech_News"),
    },
    {
      name: "Market Movers",
      value: "Market_Movers",
      checked: formData.blog_microsite.includes("Market_Movers"),
    },
  ];
  const onEditorStateChange = (e) => {
    setEditorState(e);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    setFormData((prevState) => {
      return { ...prevState, blog_content: JSON.stringify(rawContentState) };
    });
  };

  const handleFormValues = (e, key) => {
    if (key === undefined) {
      setFormData((prev) => {
        const updatedMicrosite = prev.blog_microsite.includes(e.target.value)
          ? prev.blog_microsite.filter((v) => v !== e.target.value)
          : [...prev.blog_microsite, e.target.value];

        return {
          ...prev,
          blog_microsite: updatedMicrosite,
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          [key]: e.target.value,
        };
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const body = {
        title: formData.blog_title,
        subtitle: formData.blog_subtitle,
        content: formData.blog_content,
        category: formData.blog_category,
        microsites: formData.blog_microsite.join(","),
      };

      const emptyValues = Object.values(body).some((v) => v === "");
      const micrositesEmpty = stringFormatting(formData.blog_microsite);
      if (emptyValues || micrositesEmpty === "") {
        setLoading(false);
        setMessage("Please fill all fields");
      } else {
        setAuthToken(localStorage.getItem("tokenLogin"));
        const submitForm = await axios.post("/api/blog/add", body);
        if (submitForm.status === 200) {
          setMessage(submitForm.data);
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setMessage(error?.response?.data);
      setLoading(false);
    }
  };

  const stringFormatting = (array) => {
    if (array.length === 0) return "";
    const values = array.map((v) => v.replace(/_/g, " "));
    const stringJoin = values.join(", ");
    return stringJoin;
  };

  const handleValueInputMultiSelection = (obj) => {
    if (!obj) {
      return "Website";
    } else if (Object.keys(obj).every((key) => obj[key] === false)) {
      return "Website";
    } else {
      return stringFormatting(formData.blog_microsite);
    }
  };

  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setMultiSelectionModal(false);
    }
  };
  useEffect(() => {
    if (multiSelectionModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [multiSelectionModal]);

  return (
    <div className={Styles.App}>
      <Navigation />
      {!loading ? (
        <form className={Styles.ContentWrapper} onSubmit={onSubmit}>
          <div className={Styles.TopWrapper}>
            <div className={Styles.MessageWrapper}>{message}</div>

            <Button
              type={"submit"}
              style={{
                width: "200px",
                height: "30px",
                borderRadius: "5px",
              }}
            >
              Submit
            </Button>
          </div>
          <div className={Styles.FormGrid}>
            <div className={Styles.FormLeftGrid}>
              <Input
                label
                textLabel={"Title"}
                placeholder={"Blog Title"}
                onChange={(e) => handleFormValues(e, "blog_title")}
              />
              <Input
                label
                textLabel={"Subtitle"}
                placeholder={"Blog Subtitle"}
                onChange={(e) => handleFormValues(e, "blog_subtitle")}
              />
              <Input
                label
                textLabel={"Category"}
                placeholder={"Category"}
                type={"select"}
                options={options}
                onChange={(e) => handleFormValues(e, "blog_category")}
              />
              <Input
                label
                textLabel={"Website"}
                placeholder={"Website"}
                type={"multiselect"}
                value={handleValueInputMultiSelection(formData.blog_microsite)}
                optionsMultiSelection={multiOptions}
                onChange={handleFormValues}
                multiSelectionModal={multiSelectionModal}
                setMultiSelectionModal={setMultiSelectionModal}
                modalRef={modalRef}
              />
            </div>
            <div>
              <Editor
                id="contentEditor"
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                wrapperClassName={Styles.CmsWrapper}
                editorClassName={Styles.CmsEditor}
                handlePastedText={() => false}
              />
            </div>
          </div>
        </form>
      ) : (
        <div>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default AddBlogPost;
