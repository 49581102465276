export const filterToCondition = (
  filters,
  industryObj,
  sectorObj,
  exchangeObj,
  value
) => {
  if (!filters) return null;

  const filtersMap = {
    sector: {},
    exchange_short_name: {},
    rule_of_forty: {
      "<0": {
        operator: "lt",
        operands: ["rule_of_forty", 0],
      },
      "<": {
        operator: "lt",
        operands: ["rule_of_forty", value[1]],
      },
      btwn: {
        operator: "btwn",
        operands: ["rule_of_forty", value[0], value[1]],
      },

      "0 - 40": {
        operator: "btwn",
        operands: ["rule_of_forty", 0, 40],
      },
      ">40": {
        operator: "gt",
        operands: ["rule_of_forty", 40],
      },
      ">": {
        operator: "gt",
        operands: ["rule_of_forty", value[0]],
      },
    },
    marketcap: {
      "<0.25B": {
        operator: "lt",
        operands: ["marketcap", 250000000],
      },
      "0.25B to 1B": {
        operator: "btwn",
        operands: ["marketcap", 250000000, 1000000000],
      },
      "1B to 10B": {
        operator: "btwn",
        operands: ["marketcap", 1000000000, 10000000000],
      },
      "Greater than 10B": {
        operator: "gt",
        operands: ["marketcap", 10000000000],
      },
    },
    industry: {},
  };

  if (industryObj && sectorObj && exchangeObj) {
    industryObj.options.forEach((item) => {
      filtersMap.industry[item.name] = {
        operator: "eq",
        operands: ["industry", `'${item.name}'`],
      };
    });
    sectorObj.options.forEach((item) => {
      filtersMap.sector[item.name] = {
        operator: "eq",
        operands: ["sector", `'${item.name}'`],
      };
    });
    exchangeObj.options.forEach((item) => {
      filtersMap.exchange_short_name[item.name] = {
        operator: "eq",
        operands: ["exchange_short_name", `'${item.name}'`],
      };
    });
  }

  const conditions = {
    operator: "and",
    operands: [],
  };

  Object.keys(filters).forEach((key) => {
    if (!filters[key]) return;
    if (filters[key].length > 1) {
      const innerConditions = {
        operator: "or",
        operands: [],
      };

      filters[key].forEach((filter) => {
        if (filtersMap[key][filter]) {
          innerConditions.operands.push(filtersMap[key][filter]);
        }
      });

      conditions.operands.push(innerConditions);
    } else if (filters[key].length === 1) {
      const filter = filters[key][0];
      if (filtersMap[key][filter]) {
        conditions.operands.push(filtersMap[key][filter]);
      }
    }
  });
  console.log(conditions);

  return conditions.operands.length > 0 ? conditions : null;
};
