import React from "react";
import Styles from "./RightSideModal.module.css";
import close from "../../../images/close.svg";
import { useNavigate } from "react-router-dom";
import userSvg from "../../../images/user.svg";
const RightSideModal = ({ open, setOpen, options }) => {
  const navigate = useNavigate();
  return open ? (
    <div className={Styles.ModalWrapperBackdrop}>
      <div className={Styles.ModalWrapper}>
        <div className={Styles.CloseBtn} onClick={() => setOpen(false)}>
          <img src={close} alt="close" width={20} />
        </div>
        <div className={Styles.MiddleContent}>
          {options.map((item, i) => {
            return (
              <ul key={i} className={Styles.ModalList}>
                <li
                  onClick={() => {
                    if (item.path) {
                      return navigate(item.path);
                    } else if (item.func) {
                      return item.func();
                    }
                    return;
                  }}
                >
                  {item.name === "Login" ? (
                    <img src={userSvg} alt="user" width={20} height={20} />
                  ) : (
                    item.name
                  )}
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
};

export default RightSideModal;
